<template>
  <b-modal
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('Editar dimensiones')"
    size="lg"
    ok-variant="warning"
    ok-only
    no-close-on-esc
    no-close-on-backdrop
    centered
    no-footer
    @close="onHide"
    @hide="onHide"
  >
    <form-render
      v-show="view"
      :form.sync="form"
      :fields="fields"
      @send="onAccept"
      containerButtonsClass="col-sm-12"
      ref="formRenderDimensions"
    >
    </form-render>
    <div class="text-center m-3" v-show="!view">
      <p>No olvides que en la plataforma de Mercado Libre,</p>
      <p>los envíos con modo no especificado se visualizan como</p>
      <p><strong>"Envío a nivel nacional gratis".</strong></p>
    </div>
    <template #modal-footer>
      <b-button variant="warning" @click.prevent="ok()" :disabled="loading.update">
        <b-spinner v-show="loading.update" small></b-spinner>
        {{view ? $t("Continuar"): $t("Guardar")}}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    row: { type: Object }
  },
  data() {
    return {
      fields: [],
      form: {},
      view: true,
      loading : {
        update: false
      },
      modeOptons: [{ text: 'Personalizado', id: 'custom' }, { text: 'Mercado envíos 1 [me1]', id: 'me1' }, { text: 'No especificado', id: 'not_specified' }]
    }
  },
  computed: {
    ...mapGetters({
      generalLoading: 'getLoading'
    })
  },
  watch : {
    generalLoading: {
      handler () {
        this.loading.update = !!this.generalLoading.postDimensions
      },
      deep: true
    },
    row() {
      this.setInitialValue()
    }
  },
  methods : {
    setInitialValue() {
      this.fields = [
        {fieldType: 'FieldInput', name: 'weight', label: 'Peso', type: 'number', containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required', append: {text: 'gr', color: '#BBBBBB'}},
        {fieldType: 'FieldInput', name: 'length', label: 'Longitud', type: 'number', containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required', append: {text: 'cm', color: '#BBBBBB'}},
        {fieldType: 'FieldInput', name: 'width', label: 'Ancho', type: 'number', containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required', append: {text: 'cm', color: '#BBBBBB'}},
        {fieldType: 'FieldInput', name: 'height', label: 'Alto', type: 'number', containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required', append: {text: 'cm', color: '#BBBBBB'}},
        {fieldType: 'FieldSelect', name: 'mode', label: 'Modo de envío', containerClass: 'col-sm-12 col-md-6 container-info', options: this.modeOptons, validation: 'required', change: (name, value) => this.setValueOptions('free_shipping', name, value) },
        {fieldType: 'FieldCheckbox', name: 'free_shipping', containerClass: 'col-sm-12 col-md-6 container-info mt-1', options: [{ id: 1, text: 'Marque esta opción para establecer el envío como gratuito.' }], disabled: true}
      ]

      this.form = {
        weight: this.row?.weight ?? '',
        length: this.row?.dimensions?.split('x')[0] ?? '',
        width: this.row?.dimensions?.split('x')[1] ?? '',
        height: this.row?.dimensions?.split('x')[2] ?? '',
        mode: this.modeOptons?.find(mode => this.row?.mode === mode.id),
        free_shipping: this.row?.free_shipping
      }
      this.setValueOptions('free_shipping', null, this.form.mode)
    },
    async ok (e) {
      const isCheck = await this.$refs.formRenderDimensions.onlyCheckForm()
      if (this.view && isCheck) {
        this.view = false
      } else {
        this.$refs.formRenderDimensions.checkForm()
      }
    },
    onAccept(data) {
      this.$emit('postDimensions', data)
    },
    onHide() {
      this.view = true
    },
    setValueOptions(target, name, value) {
      const findIndex = this.fields.findIndex((field) => field.name === target)
      const isSelectMe1 = value.id !== 'me1'
      this.fields[findIndex].disabled = isSelectMe1
      if (value.id !== 'me1') this.form.free_shipping = false
    }
  }

}
</script>

<style>

</style>