<template>
  <b-modal
    v-bind="$attrs"
    v-on="$listeners"
    size="lg"
    ok-variant="warning"
    ok-only
    no-close-on-esc
    no-close-on-backdrop
    centered
    no-footer
    @show="onShow"
  >
    <template #modal-title>
      <h2>Actualización Masiva</h2>
    </template>
    <form-render
      :form.sync="form"
      :fields="fields"
      @send="onAccept"
      containerButtonsClass="col-sm-12"
      ref="formRenderUpload"
    >
    </form-render>
    <div>
      <a href="https://storage.googleapis.com/templates-excel/ejemplo-masiva-items-meli.xlsx">Descarga formato de actualización masiva. </a>
      <p>Podrás modificar las dimensiones de los artículos, activarlos y establecer si un artículo será gratuito.</p>
    </div>
    <template #modal-footer>
      <b-button variant="danger" @click="hide">
        {{$t("Cancelar")}}
      </b-button>
      <b-button variant="success" @click.prevent="ok()" :disabled="loading">
        <b-spinner v-show="loading" small></b-spinner>
        {{$t("Aceptar")}}
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: ['loading'],
  data() {
    return {
      fields: [],
      form: {}
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  methods: {
    onShow() {
      this.setInitialData()
    },
    setInitialData() {
      this.fields = [
        {fieldType: 'FieldInput', name: 'email', label: 'Correo electrónico', containerClass: 'col-12', disabled: true},
        {fieldType: 'FieldFileDropArea', name: 'excel', validation: 'required', containerClass: 'col-12 mt-1', accept: '.xlsx', classField:'hide-button-browse', labelFormFile: 'Seleccionar archivo'}
      ]
      this.form.email = this.mySession?.email
    },
    ok() {
      this.$refs.formRenderUpload.checkForm()
    },
    onAccept(form) {
      this.$emit('postUploadFileProducts', form)
    },
    hide() {
      this.$bvModal.hide('mkcUpladFileProducts')
    }
  }
}
</script>
<style>
.remove-botton {
  right: 2.5% !important;
}
</style>
