<template>
  <div style="min-height: 125px">
    <b-card style="min-height: 125px">
      <b-col cols="12" class="mt-2 d-flex justify-content-end" v-if="fieldsSelect.length > 0 && isSelectedShipper">
        <b-button variant="warning" @click="openModal('mkcUpladFileProducts')"><feather-icon icon="UploadIcon" style="margin-right:8px"/><span>Actualización Masiva</span></b-button>
      </b-col>
      <filter-swapper :buttons="buttons" :controlHeightButtons="controlHeight">
        <template #slot1>
          <form-render :form.sync="formSelect" :key="keyFormRender" :fields="fieldsSelect" @send="filterList" :buttonSend="buttonSend" ref="formFilter"
            containerButtonsClass="col-sm-12 col-md-auto col-lg-4 col-xl-auto container-button mt-2" class="mb-2">
            <template #buttons>
              <b-button variant="outline-light" v-b-tooltip.hover title="Limpiar filtros" class="ml-1" @click="cleanFilter"><feather-icon icon="RefreshCwIcon"/></b-button>
            </template>
          </form-render>
        </template>
      </filter-swapper>
      <div v-show="!loading.total" class="table-listproducts">
        <table-render
          :schema.sync="schema"
          :rows="rows"
          :loading="loading.products"
          :actions="actions"
          :stickyHeader="`calc(100vh - 2.5rem)`"
          id="listProducts"
          :tableClass="'with-scroll'"
          :striped="true"
          :showCheckboxes="false"
        >
          <template #mode="scope">
            <b-badge :variant="scope.rowdata.mode === 'custom' ? 'light' : ''">{{scope.rowdata.mode === 'custom' ? 'Perzonalizado' : scope.rowdata.mode === 'me1'? 'Me1' : 'No especificado'}}</b-badge>
          </template>
          <template #free_shipping="scope">
          <i v-if="scope.rowdata.free_shipping" class="fa fa-check-circle text-success"></i>
          <span v-if="!scope.rowdata.free_shipping" class="no-info-center"> -- </span>
        </template>
        </table-render>
        <pagination :pagination="pagination" :noDigits="false" :showSize="true" :optionSizes="sizes"/>
      </div>
      <div class="table-render-skeleton mt-2" v-if="loading.products">
        <b-skeleton-table :rows="7" :columns="schema.length || 7" :table-props="{}" />
      </div>
    </b-card>
    <modal-products
    id="mktProductModal"
    :row="selectRow"
    @postDimensions="postDimensions">
    </modal-products>
    <modal-upload-file-products
    id="mkcUpladFileProducts"
    @postUploadFileProducts="postUploadFileProducts"
    :loading="loading.upload"
    />
  </div>
</template>

<script>
import { BCol } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ModalProducts from './ModalProducts.vue'
import ModalUploadFileProducts from './ModalUploadFileProducts.vue'
import BaseServices from '@/store/services/index'
export default {
  components: { ModalProducts, BCol, ModalUploadFileProducts },
  data() {
    return {
      controlHeight: { class: 'row mb-2 spacing-label-field'},
      buttonSend: {title: 'Buscar', icon: 'SearchIcon', color: 'warning'},
      buttons: [],
      keyFormRender: 0,
      fieldsSelect: [],
      formSelect: {},
      shipper_id: null,
      marketcenter_cod: null,
      mode_cod: null,
      schema: [],
      rows: [],
      actions:[],
      selectRow: {},
      sizes: [{id: 3, text: 3}, {id: 10, text: 10}, {id: 20, text: 20}],
      modeOptions: [{ text: 'Personalizado', id: 'custom' }, { text: 'Mercado envíos 1 [me1]', id: 'me1' }, { text: 'No especificado', id: 'not_specified' }],
      pagination: {
        page: 1,
        total: 0,
        limit: 20
      },
      loading: {
        organizations: true,
        shippersByOrganization: true,
        marketcenters: false,
        products: false,
        upload: false,
        total: true,
        second: true
      },
      baseService: new BaseServices(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      organizations: 'getOrganizations',
      shippersByOrganization: 'getShippersByOrganization',
      deliveries: 'getDeliveriesSuccess',
      generalLoading: 'getLoading',
      marketcenters: 'getMarketcenterByShipperId',
      products: 'getProductsMarketcenter'
    }),
    isSelectedShipper() {
      return !!this.shipper_id
    }
  },
  watch: {
    marketcenters () {
      const regex = /^ML/gm
      const options = this.marketcenters?.filter((m) => m.code.match(regex))?.map(({code, name}) => ({id: code, text: name}))
      this.setSelectOptions('marketcenter_cod', { options })
    },
    products(value) {
      if (value?.total_items > 0) {
        this.rows = value?.results?.map((p) => {
          return {
            id: p?.body?.id,
            product_name: p?.body?.title,
            mode: p?.body?.shipping?.mode,
            weight: p?.body?.shipping?.dimensions?.split(',')[1],
            dimensions: p?.body?.shipping?.dimensions?.split(',')[0],
            free_shipping: p?.body?.shipping?.free_shipping ?? false
          }
        })
      } else {
        this.rows = []
      }
      this.pagination.total = value.total_items
      this.loading.second = false
    },
    mySession() {
      if (this.mySession.id) this.setInitialData()
    },
    organizations () {
      this.setSelectOptions('organization_id', { options: this.organizations.rows })
    },
    shippersByOrganization () {
      const shipperOptions = this.shippersByOrganization.map((shipper) => ({...shipper, name: shipper.name1, text: shipper.name1 }))
      this.setSelectOptions('shipper_id', { options: shipperOptions })
    },
    generalLoading: {
      handler () {
        this.loading.organizations = !!this.generalLoading.getOrganizationsGlobal
        this.loading.shippersByOrganization = !!this.generalLoading.getShippersByOrganization
        this.loading.marketcenters = !!this.generalLoading.marketcenters
        this.loading.products = !!this.generalLoading.getProductsMarketcenter
      },
      deep: true
    },
    loading: {
      handler () {
        const prevTotal = this.loading.total
        this.loading.total = !Object.keys(this.loading).filter(key => key !== 'total').every(key => !this.loading[key])
        if (prevTotal !== this.loading.total) this.keyFormRender++
      },
      deep: true
    },
    'pagination.page' () {
      this.filterList()
    },
    'pagination.limit' () {
      if (this.pagination.page === 1) this.filterList()
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.schema = [
        {label: 'ID', key: 'id', sortable: true},
        {label: 'Producto', key: 'product_name', sortable: true},
        {label: 'Modo', key: 'mode', sortable: true, useSlot: true},
        {label: 'Envío gratuito', key: 'free_shipping', class: ['text-center'], sortable: true, useSlot: true},
        {label: 'Peso', key: 'weight', sortable: true, class: ['text-center'], doubleDash: true},
        {label: 'Dimensiones', key: 'dimensions', sortable: true, class: ['text-center'], doubleDash: true},
        {label: 'Acciones', key: 'actions', class: ['text-center']}
      ]

      this.actions = [
        {action: id => this.actionEdit(id), icon: 'Edit2Icon', color: 'warning', text: 'Editar' }
      ]

      if (['admin', 'superadmin'].includes(this.$session.get('cas_user').role)) {
        this.fieldsSelect = [
          {fieldType: 'FieldSelect', name: 'organization_id', label: 'Organización', useLabel: true, containerClass: 'col-sm-12 col-md-3', clearable: true, persistSearch: true, searchOnType: { fx: e => this.getOrganizationsFetch(e), nChars: 3, debounce: 600 }, change: this.changeOrganization, validation: 'required', options: this.organizations.rows },
          {fieldType: 'FieldSelect', name: 'shipper_id', label: 'Seller', useLabel: true, containerClass: 'col-sm-12 col-md-3', clearable: true, dependency: 'organization_id', change: this.changeShipper, validation: 'required' },
          {fieldType: 'FieldSelect', name: 'marketcenter_cod', label: 'Marketplace', useLabel: true, containerClass: 'col-sm-12 col-md-2', clearable: true, dependency: 'shipper_id', change: this.changeMarketcenter, validation: 'required' },
          {fieldType: 'FieldSelect', name: 'mode_cod', label: 'Modo de envío', useLabel: true, containerClass: 'col-sm-12 col-md-2', clearable: true, options: this.modeOptions, change: this.changeMode, validation: 'required' }
        ]

      } else if (this.$session.get('cas_user').role === 'marketplace') {
        this.organizationId = this.$session.get('cas_user').organization.id
        this.fieldsSelect = [
          {fieldType: 'FieldSelect', name: 'shipper_id', label: 'Seller', useLabel: true, placeholder: 'Nombre de la empresa', clearable: true, change: this.changeShipper, validation: 'required' },
          {fieldType: 'FieldSelect', name: 'marketcenter_cod', label: 'Marketplace', useLabel: true, placeholder: 'Nombre del marketplace', clearable: true, dependency: 'shipper_id', change: this.changeMarketcenter, validation: 'required' },
          {fieldType: 'FieldSelect', name: 'mode_cod', label: 'Modo de envío', useLabel: true, containerClass: 'col-sm-12 col-md-2', clearable: true, options: this.modeOptions, change: this.changeMode, validation: 'required' }
        ]
        this.changeOrganization('Organization', this.$session.get('cas_user').organization)
      } else {
        this.shipper_id = this.$session.get('cas_user')?.shipper?.id
        this.fieldsSelect = [
          {fieldType: 'FieldSelect', name: 'marketcenter_cod', label: 'Marketplace', useLabel: true, placeholder: 'Nombre del marketplace', clearable: true, change: this.changeMarketcenter, validation: 'required' },
          {fieldType: 'FieldSelect', name: 'mode_cod', label: 'Modo de envío', useLabel: true, containerClass: 'col-sm-12 col-md-2', clearable: true, options: this.modeOptions, change: this.changeMode, validation: 'required' }
        ]
        this.getMarketcenter(this.shipper_id)
      }
    },
    getOrganizationsFetch(name) {
      const data = { 
        name: 'getOrganizations',
        queryParams: {name, simplified: true }
      }
      this.$store.dispatch('fetchService', data)
    },
    postDimensions(form) {
      const queryParams = {
        item_id: this.selectRow?.id,
        marketcenter_code:this.marketcenter_cod,
        weight: form.weight,
        mode: form?.mode?.id,
        free_shipping: form?.free_shipping ?? false,
        dimensions: {
          length: form.length,
          width: form.width,
          height: form.height
        }
      }
      const data = { 
        name: 'postDimensions',
        params: {shipper_id: this.shipper_id},
        queryParams,
        onSuccess: () => {
          this.$bvModal.hide('mktProductModal')
          const index = this.rows.findIndex((product) => product.id === queryParams.item_id)
          this.rows[index].weight = queryParams.weight
          this.rows[index].dimensions = `${queryParams.dimensions.length}x${queryParams.dimensions.width}x${queryParams.dimensions.height}`
        }
      }
      this.$store.dispatch('fetchService', data)
    },
    postUploadFileProducts(form) {
      this.loading.upload = true
      const params = {
        shipper_id: this.shipper_id
      }
      const queryParams = {
        email: form.email,
        excel: form.excel
      }
      this.baseService.callUploadFileCustomParams('postUploadProducts', queryParams, params)
        .then(response => {
          if (response.code === 'success') {
            this.$bvModal.hide('mkcUpladFileProducts')
            this.$success('Archivos agregados correctamente')
          }
        })
        .catch(err => {
          const text = 'Ocurrió un problema al subir el archivo'
          let textMessage = ''
          if (Array.isArray(err)) {
            textMessage += '<ul style="list-style-type: disc;">'
            err.forEach(element => {
              textMessage += `<li>${element.message}</li>`
            })
            textMessage += '</ul>'
          }
          this.$alert(textMessage ? textMessage : text)
        })
        .finally((_) => {
          this.loading.upload = false
        })
    },
    openModal(name) {
      this.$bvModal.show(name)
    },
    actionEdit(id) {
      this.selectRow = this.rows?.find((r) => r.id === id)
      this.openModal('mktProductModal')
    },
    filterList(form) {
      const shipper_id = form?.shipper_id?.id ?? this.shipper_id
      const marketcenter_code = form?.marketcenter_cod?.id ?? this.marketcenter_cod
      const mode = form?.mode_cod?.id ?? this.mode_cod
      const data = {
        name: 'getProductsMarketcenter',
        params: {shipper_id, limit: this.pagination.limit, offset:(this.pagination.page - 1).toString(), mode },
        queryParams : { marketcenter_code },
        onError: () => { this.loading.products = false; this.loading.second = false }
      }
      this.$store.dispatch('fetchService', data)
    },
    changeOrganization (name, value) {
      this.cleanFields(['shipper_id', 'marketcenter_cod', 'mode_cod'])
      this.formSelect = {
        ...this.formSelect,
        shipper_id: null
      }
      const index = this.fieldsSelect.findIndex(el => el.name === 'shipper_id')
      this.fieldsSelect[index].options = []
      if (!value?.id) {
        return null
      }
      
      const queryParams = {
        page: 1,
        paginate_by: 99999
      }
      const params = {
        organization_id: !!this.organizationId ? this.organizationId : this.formSelect.organization_id?.id || 0
      }
      this.fieldsSelect[index].useSkeleton = true
      this.$store.dispatch('fetchService', { name: 'getShippersByOrganization', queryParams, params, onSuccess: () => this.fieldsSelect[index].useSkeleton = false })
      // this.keyFormRender++
    },
    cleanFilter (e) {
      this.formSelect = {}
    },
    setSelectOptions (name, { options }) {
      const index = this.fieldsSelect.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fieldsSelect[index].options = options
        if (!['organization_id'].includes(name)) this.keyFormRender++
      }
    },
    getMarketcenter(shipper_id) {
      const index = this.fieldsSelect.findIndex(el => el.name === 'marketcenter_cod')
      this.fieldsSelect[index].useSkeleton = true
      const data = {
        name: 'getMarketcenterByShipperId',
        params: {shipper_id},
        onSuccess: () => this.fieldsSelect[index].useSkeleton = false
      }
      this.$store.dispatch('fetchService', data)
    },
    changeShipper(_, value) {
      this.shipper_id = value.id
      this.getMarketcenter(value.id)
    },
    changeMarketcenter(_, value) {
      this.marketcenter_cod = value.id
    },
    changeMode(_, value) {
      this.mode_cod = value.id
    },
    cleanFields(array) {
      array.forEach((el) => this.formSelect[el] = null)
      this.keyFormRender++
    }
  }
}
</script>
<style>
.table-listproducts .table-render-skeleton {
  overflow-x: hidden !important;
}
</style>